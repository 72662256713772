.brands {
    width: 70%;
    margin-left: 15%;

    .brand {
        display: flex;
        margin-top: 16px;
        flex-direction: row;
        padding: 16px 24px;
        background: #FFFFFF;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        cursor: pointer;

        .brandName {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            margin: 0px !important;
        }

        .brandEmail {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.15px;
            color: #60606E;
        }
    }

    .caption {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #60606E;
    }
}

.selectbrand {
    text-align: center;
}

@media screen and (max-width:600px) {
    .brands {
        width: 90%;
        padding: 10px;
        margin: 0 auto;
    }
}