.navbar {
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    width: 58%;
}


.HeadingH24R {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}


.SubtitleS14M {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

}

.customerdetail {
    padding: 0px 24px;

    .box {
        display: flex;
        width: 70%;
        margin-left: 15%;
        flex-direction: row;
        padding: 16px 24px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        background: #FFFFFF;

        .key {
            color: #60606E
        }

        .value {
            font-weight: 600 !important;
        }
    }

}


.flow {
    .buttonflex {
        width: 70%;
        margin-left: 15% !important;
        display: flex;
        flex-direction: row;
        grid-gap: 20px;
        margin-top: 52px;

        .spending {
            width: 50%;
            padding: 16px 0px;
            background: #1059FA;
            border-radius: 6px;
            color: white;
            border: none;
        }

        .redeemed {
            width: 50%;
            padding: 16px 0px;
            background: #F5F8FE;
            border-radius: 6px;
            color: #1059FA;
            border: none;
        }

        .redeemed:focus {
            outline: none !important;
        }

        .spending:focus {
            outline: none !important;
        }
    }
}



.deals {
    width: 70%;
    margin-left: 15%;

    .deal {
        padding: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 14px !important;
        cursor: pointer;
        justify-content: space-between;

        .colm1 {
            width: 80%;
            display: flex;
            flex-direction: column;
        }

        .colm2 {
            .dealimage {
                border-radius: 6px;
            }
        }

        .condition {
            background: #1059FA;
            border-radius: 24px;
            padding: 5px 16px;
            color: white;
            margin-top: 8px;
            width: fit-content;
        }
    }

    .invalid {
        opacity: 0.5;
        cursor: auto;
        pointer-events: none;
    }

    .headingdeals {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 24px;
    }


    .HeadlineH20M {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    .caption {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
}
.firstChildMarginTop:first-child{
    margin-top: 46px !important;
}


.spendingModal {
    text-align: center;
    padding: 40px 60px;

    .amounttext {
        margin-top: 20px;

        .amount {
            margin-top: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 40px;
            color: #1059FA;

        }
    }

    .buttonflex {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        grid-gap: 20px;
        margin-top: 30px;
        margin-left: 0px !important;
    }

    .head1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #000000;
    }

    .caption {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #999B9D;

    }


    .headsuccess {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #484951;
        margin-top: 26px;

    }

}


.successBtn {
    width: 100%;
    margin-top: 36px;
    padding: 12px 0px;
    background: #1059FA;
    border-radius: 6px;
    color: white;
    border: none;
}

@media only screen and (max-width: 600px) {

    .navbar {
        width: 100%;
        display: block;


    }


    .res-cus-head {

        padding-top: 10px;
    }

    .deals {
        width: 100% !important;
        margin-left: 0px !important;
        padding: 10px;
        overflow-y: auto !important;
    }

    .res-cus-info {
        width: 100% !important;
        display: flex !important;
        gap: 20px !important;
        margin-left: 0px !important;
    }

}