@import './helpers/fonts.scss';
@import './helpers/variables.scss';
@import './helpers/font-size.scss';
@import './helpers/font-wieght.scss';
@import './helpers/spacing.scss';
@import './helpers//color-scheme.scss';
@import './helpers/width.scss';
@import './helpers/opacity.scss';

@import './Flow.scss';
@import './Deal.scss';
@import './brand.scss';
@import './customer.scss';
@import './spend.scss';
@import './ReadFlow.scss';



//Font Import

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 8px;
}

.cp {
  cursor: pointer;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

input {
  font-size: 16px;
}

input::placeholder {
  font-size: 16px !important;

}

.d-flex {
  display: flex !important;
  flex-direction: row !important;
}

.capitalize {
  text-transform: capitalize;
}

.Loader {
  height: 100vh;
  width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px !important;
}

.borderRadius-50 {
  border-radius: 50%;
}

.justify-flex-end {
  justify-content: flex-end !important;
}

.side-menu-active {
  font-weight: 500;
}

.space-between {
  justify-content: space-between;
}


#SignUp {
  .signup-form {
    max-width: 576px;
    margin-top: 47px;
  }

  .content {
    max-width: 473px;
  }
}



/* Media Queries */

/* xs */
@media (max-width: 575px) {
  body {
    font-size: 4vw;
  }

  .main-heading {
    font-size: 7vw;
  }
}

/* sm */
@media (min-width: 576px) and (max-width: 767px) {}

/* md */
@media (min-width: 768px) and (max-width: 991px) {}

/* lg */
@media (min-width: 992px) and (max-width: 1199px) {}

/* xl */
@media(min-width: 1200px) {}

/* xxl */
@media(min-width: 1600px) {}


.loader {
  text-align: center;
  margin-top: 50px;
}



#Toaster .notification-container {
  font-size: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
}

#Toaster .top-right {
  top: 40px;
  right: 12px;
  -webkit-transition: -webkit-transform .6s ease-in-out;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  -webkit-animation: toast-in-right .7s;
  animation: toast-in-right .7s;
}

#Toaster .bottom-right {
  bottom: 12px;
  right: 12px;
  -webkit-transition: -webkit-transform .6s ease-in-out;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  -webkit-animation: toast-in-right .7s;
  animation: toast-in-right .7s;
}

#Toaster .top-left {
  top: 12px;
  left: 12px;
  -webkit-transition: -webkit-transform .6s ease-in;
  transition: -webkit-transform .6s ease-in;
  transition: transform .6s ease-in;
  transition: transform .6s ease-in, -webkit-transform .6s ease-in;
  -webkit-animation: toast-in-left .7s;
  animation: toast-in-left .7s;
}

#Toaster .bottom-left {
  bottom: 12px;
  left: 12px;
  -webkit-transition: -webkit-transform .6s ease-in;
  transition: -webkit-transform .6s ease-in;
  transition: transform .6s ease-in;
  transition: transform .6s ease-in, -webkit-transform .6s ease-in;
  -webkit-animation: toast-in-left .7s;
  animation: toast-in-left .7s;
}


#Toaster .notification {
  background-color: #f5f7fc;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 16px;
  margin-bottom: 15px;
  width: 300px;
  max-height: 100px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 12px rgba(128, 128, 128, 0.24);
  box-shadow: 0px 0px 12px rgba(128, 128, 128, 0.24);
  color: #000;
  opacity: .9;
  background-position: 15px;
  background-repeat: no-repeat;
}

#Toaster .notification:hover {
  -webkit-box-shadow: 0 0 12px #fff;
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

#Toaster .notification-title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
  color: inherit;
}

#Toaster .notification-message {
  margin: 0;
  text-align: left;
  margin-left: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}

#Toaster .notification-image {
  float: left;
  margin-right: 15px;
  margin-top: 5px;
}

#Toaster .notification-image img {
  width: 30px;
  height: 30px;
}

#Toaster .toast {
  min-height: 50px;
  width: 324px;
  color: #fff;
  padding: 16px;
}

#Toaster .notification-container button {
  font-weight: 700;
  color: inherit;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.align-flex-start {
  align-items: flex-end;
}

.justify-flex-start {
  align-items: flex-start;
}

#Toaster .error {
  color: #F25053;
}

#Toaster .warning {
  color: #F2A750;
}

#Toaster .info {
  color: #3456E8;
}

#Toaster .success {
  color: #30be64;
}

#Toaster .notification-message {
  color: #45526B;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.justify-content-space-between {
  justify-content: space-between;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-infinite {
  animation: rotate 1s linear infinite;
  /* Adjust the duration and timing function as needed */
}

.p_btns{
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.k_btn {
  width: 33%;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.64px;
  cursor: pointer;
  height: 40px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  justify-content:center ;
  align-items: center;
}







// 
.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 300px;
}

.key {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 20px;
  padding: 30px 0px 30px 0px;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.key:hover {
  background-color: #e8e8e8;
}

.key.delete {
  color: red;
}

.dot {
  font-size: 25px;
  vertical-align: middle;
}
// 
.switch-container {
  display: flex;
  width: 300px;
  border: 2px solid #ccc;
  border-radius: 30px;  /* Apply a uniform border-radius here */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ccc;
  overflow: hidden;  /* Ensures that child elements conform to the container's rounded border */
}

.switch-option {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
  border-radius: 28px; /* Always keep border-radius to prevent glitch */
  background-color: #ccc;
}

.switch-option.active {
  background-color: #fff;
  // border-radius: 18px;
}
// 


.width-available{
  width: -webkit-fill-available;  /* Chrome and Safari */
  width: -moz-available;          /* Modern versions of Firefox */
  width: fill-available; 
}
.disabled-div {
  pointer-events: none; /* Disables clicking and other pointer events */
  opacity: 0.4 !important; /* Makes the div look faded or disabled */

}

.headAA{
  color: #010101;
font-family: Inter;
font-size: 17.497px;
font-style: normal;
font-weight: 500;
line-height: 20.997px; /* 120% */
letter-spacing: 0.087px;
}
.conditionAA{
  color: #60606E;
font-family: Inter;
font-size: 13.998px;
font-style: normal;
font-weight: 400;
line-height: 19.247px; /* 137.5% */
letter-spacing: -0.262px;
}
.headAB{
  color: #60606E;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 109.091% */
letter-spacing: 0.2px;
}