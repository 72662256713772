.deals {
    width: 70%;
    margin-left: 15%;
    padding-bottom: 30px;
    height: 342px;
    overflow-y: scroll;

    .deal {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 14px;
        cursor: pointer;
        justify-content: space-between;
        border: 2px solid transparent;

        .colm1 {
            width: 80%;
            display: flex;
            flex-direction: column;
        }

        .colm2 {
            .dealimage {
                border-radius: 6px;
            }
        }

        .condition {
            // background: #1059FA;
            // border-radius: 24px;
            // padding: 5px 16px;
            // color: white;
            // margin-top: 8px;
            // width: fit-content;
            color: #0A0B0D;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1px;
            border-radius: 24px;
            border: 1px solid #000;
            background-color: transparent;
            margin-top: 8px;
            background-color: #F1F1F1;
        }
    }

    .selected-deal {
        border-radius: 8px;
        border: 2px solid #1059FA;
        box-shadow: 0px 4px 14px 0px rgba(16, 89, 250, 0.08);
        background: rgba(16, 89, 250, 0.05);


    }

    .headingdeals {
        color: #000;
        font-family: 'Inter';
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 109.091% */
        letter-spacing: 0.2px;
    }


    .HeadlineH20M {
        color: #010101;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
    }

    .caption {
        // font-family : 'Inter';
        // font-style  : normal;
        // font-weight : 400;
        // font-size   : 16px;
        // line-height : 22px;
        // margin-top  : 10px;
        color:  #60606E;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.3px;
    }



    .spending {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        cursor: pointer;
        color: #1059FA;
    }


    .user {
        margin-top: 25%;
        border: 1px solid #999B9D;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 28px 30px;
        width: 100%;


        .col1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #000000;
        }

        .col2 {

            .passcode {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                color: #000000;
            }

            .caption {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 16px;
                color: #60606E;
            }

        }
    }
}

.buttonflex {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    margin-left: 0px !important;
    margin-top: 52px;

    .spending {
        width: 50%;
        padding: 16px 0px;
        background: #1059FA;
        border-radius: 6px;
        color: white !important;
        border: none;
    }

    .redeemed {
        width: 50%;
        padding: 16px 0px;
        background: #F5F8FE;
        border-radius: 6px;
        color: #1059FA;
        border: none;
    }

    .redeemed:focus {
        outline: none !important;
    }

    .spending:focus {
        outline: none !important;
    }
}



.dealredeemimage {
    text-align: center;
    margin-top: 50px;
}

.dealredeemname {
    width: 40%;
    margin-left: 30%;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    color: #1059FA;
    margin-top: 20px;
}

.done {
    width: 100% !important;
}