.cardBox {
    padding: 16px !important;
}

@media screen and (min-width:1200px) {
    .cardBox {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        width: 100%;
    }

}

@media screen and (min-width:600px) {}

@media screen and (min-width:768px) {

    .cardBox {
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        padding: 5px;
    }

    .customer {
        grid-gap: 20px;
    }


    .scanner {
        height: 20px;

        /* Subtitle/S14M */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        letter-spacing: 0.1px;

        /* Light/Heading */

        color: #141414;
    }

    .sub-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        letter-spacing: 0.15px;

        /* Light/Caption */

        color: #60606E;
    }

    .selected {
        display: flex;
        border-color: blue;
        box-sizing: border-box;
    }

    .card-body-settings {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 15px;


    }

    .icon {
        width: 18px;
        height: 18px;
    }

    .card {
        padding-right: 12rem;
    }

    .label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        letter-spacing: 0.15px;
    }



}

.body-font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.15px;

    /* Light/Caption */

    color: #60606E;
}

.headline {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: 0.1px;

    /* Light/Heading */

    color: #141414;
}

.vector-1 {
    position: absolute;
    right: 15px;
    top: 40px;
    height: 28px;
    width: 28px;
}

.vector-2 {
    position: absolute;
    right: 60px;
    top: 40px;
    width: 28px;
    height: 28px;
}

.gift-white {
    display: none;
}

.gift-black {
    display: block;
}

.login-white {
    display: none;
}

.login-black {
    display: block;
}

@media screen and (max-width:490px) {


    .login-white {
        display: block;
    }

    .login-black {
        display: none;
    }

    .gift-white {
        display: block;
    }

    .gift-black {
        display: none;
    }


    .header-bg-res {
        width: 100%;
        background: #1059FA;
    }

    .responsive-boxes {

        // width: 95%;
        padding: 10px;
    }

    .res-white {
        color: #FFFFFF;
    }

    .res-padding {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .main-box-res {

        width: 100% !important;
        height: auto !important;
    }

    .ph-responsive {

        width: 90% !important;
        margin-top: 50px !important;
        padding: 8px !important;
    }

    .btn-sheild {

        width: 100%;
        justify-content: center;
    }

    .icon {
        height: 18px;
        width: 18px;
    }



    .selected {
        border-color: blue !important;
        box-sizing: border-box;
    }

    .cardBox {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        border: 1px solid #DDDDDD;
    }

    .customer {
        grid-gap: 20px;
        flex-direction: column !important;
        background-color: #FFFFFF;
        position: fixed !important;
        bottom: 0 !important;

    }

    .plr-res {

        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}