.customermodal{
    padding : 20px 20px;
    .navbar {
        display        : flex;
        padding        : 20px 0px !important;
        flex-direction : row;
        width          : 70% !important;
    }   

    .subtext{
        text-align  : center;
        font-family : 'Inter';
        font-style  : normal;
        font-weight : 400;
        font-size   : 16px;
        color       : #60606E;
        line-height : 24px;
    }

    .user{
        padding       : 16px 24px;
        background    : #FFFFFF;
        box-shadow    : 0px 2px 12px rgba(0, 0, 0, 0.12);
        border-radius : 6px;
        cursor        : pointer;
        margin-top    : 20px;

        .s1{
            font-family : 'Inter';
            font-style  : normal;
            font-weight : 600;
            font-size   : 14px;
            line-height : 20px;
            color       : #141414;
        }

        .s2{
            font-family : 'Inter';
            font-style  : normal;
            font-weight : 400;
            font-size   : 14px;
            line-height : 20px;
            color       : #60606E;

        }
    }
}
