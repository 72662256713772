.bg {
    background: linear-gradient(159.17deg, #2861DF 13.82%, #0369FC 76.22%), #FFFFFF;
    height: 500px !important;
    width: 100% !important;
    text-align-last: center;
}

.bg2 {
    background: #1059FA;
    height: 500px !important;
    width: 100% !important;
    text-align-last: center;

}

.card {

    height: 736px;
    width: 100%;
}

.logo {
    height: 200px;
    width: 200px;
}


.qrReader {
    width: 500px !important;
    height: 400px !important;
    margin-left: 32% !important;
}

.headline {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    text-align: center;
    letter-spacing: 0.1px;

    /* White */

    color: #FFFFFF;
}

.qr-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;

}

.spinner {
    height: 6rem;
    width: 6rem;
}




@media screen and (max-width:700px) {
    .qrReader {
        width: 90.5% !important;
        height: 300px !important;
        margin-left: 5% !important;
    }

    // .bg {
    //     height: 312px !important;
    // }


    .for-mtzero {

        margin-top: 0px !important;
    }

}



@media (min-width: 700px) and (max-width: 900px) {
    .qrReader {
        margin-left: 20% !important;
    }
}