$colors: (
    "text"          : $text,
    "subText"       : $subText,
    
    "actionBtn"     : $actionBtn,
    "signupBtn"     : $signupBtn,

    "activeNav"     : $activeNav,
    "inactiveNav"   : $inactiveNav,

    "activeTab"     : $activeTab,
    "inactiveTab"   : $inactiveTab,

    "label"         : $label,
    "paragraph"     : $paragraph,

    "white"         : $white
);

@each $name, $color in $colors {
    .color-#{$name} {
        color: #{$color} !important;
    }

    .bg-color-#{$name} {
        background-color: #{$color} !important;
    }
}

