.ModalMainz button:first-child {
    margin-top: 48px !important;

    margin-right: 14px !important;
    color: #F5F8FE;
    background-color: #1059FA;
    width: 250px !important;
    height: 52px !important;
}

.ModalMainz button:last-child {
    margin-top: 48px !important;

    margin-left: 14px !important;
    background-color: #F5F8FE;
    color: #1059FA;
    width: 250px !important;
    height: 52px !important;
}

.fs-88Imp {
    font-size: 88px !important;
    // width: 650px !important;
    text-align: center;
}


.ccSace::placeholder {
    font-size: 88px !important;
    color: rgba(255, 255, 255, 0.405)
}

.mt_40 {
    margin-top: 40px !important;
}

.infosDiv {
    width: 70% !important;
    height: 75px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.infosDiv h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    padding-left: 30px;
}

.infosDiv div {
    padding-right: 30px;
}

.infosDiv div p {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #DDDDDD;
    text-align: right;
}

.infosDiv div h2 {
    font-weight: 400 !important;
    font-size: 23px !important;
    line-height: 20px !important;
    letter-spacing: 0.15px !important;
    color: #FFFFFF !important;
    padding-left: 0px !important;
}

.ModalMainz {
    width: 589px;
    height: 353px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ModalMainz h3 {
    margin-top: 43px !important;
    font-weight: 300;
    font-size: 23px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.3px;
    width: 326px;
    color: #000000;
}

.ModalMainz p {
    margin-top: 48px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #999B9D;
}

.ModalMainz h2 {
    margin-top: 19px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #1059FA;
}

.disabled {
    // background: #9b9b9b !important;
    opacity: .65;
    pointer-events: none !important;
}

.cptwo {
    position: absolute;
    right: 55px;
    cursor: pointer;
    top: 40px;
    height: 28px;
    width: 28px;
}

.home_back {
    color: #1059FA;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width:490px) {
    .p_btns {
        width: 90% !important;
    }

    .res-spend {

        width: 100% !important;
        font-size: 16px;
    }

    .infosDiv {
        width: 100% !important;

    }

    .infosDiv div {
        padding-right: 20px;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .infosDiv div p {

        text-align: center;
    }

    // .res-continue {

    //     position: static !important;
    //     // margin-top: 30px;
    //     width: 90% !important;
    //     margin: 60px auto;
    // }

    .ModalMainz {
        width: 100%;
    }

    #ButtonsMainz {

        width: 90% !important;
        display: flex;
        justify-content: center;
    }
}





#spend {
    .customer-logo-Image {
        width: 40px;
        height: 40px;
    }

    .customer-logo-Image img {
        width: 100%
    }

    .mt_34 {
        margin-top: 34px !important;
    }

    .pt_34 {
        padding-top: 34px !important;
    }

    .pb_34 {
        padding-bottom: 34px !important;
    }

    .pb_15 {
        padding-bottom: 15px !important;
    }

    .customer-info {
        margin-left: 10px;
    }

    .customer-info-name {
        color: #141414;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    .customer-info-email {
        color: #60606E;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.15px;
    }

    .bigInput {
        margin: 0 auto;
        padding-top: 64px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .main-points-amount {
        display: flex;
        gap: 26px;
    }

    .main-points-amount div {
        color: #999B9D;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.1px;
        cursor: pointer;
    }

    .amount-points-selected {
        color: #000 !important;
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.1px;
    }

    .first-blackLetter::first-letter {
        color: #000 !important;
    }

    .available-points {
        padding-top: 15px;
        color: #60606E;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        display: flex;
    }

    .available-points div {
        color: #141414;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
}