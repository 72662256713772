#MainReadFlowDataPage {
    height: 100vh;


    .MainReadFlowDataPageFirst {
        height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;

    }

    .capitalize {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.1px;
        color: #141414;
    }

    .econs div img {
        // height: 28px !important;
        // width: 28px !important;
    }

    #ReadFlow {
        margin: 0 auto;
        display: flex;
        // padding-top: 20px;
        height: 85%;

        .customerMain {
            height: 85%;
            overflow-y: scroll;
        }

        .customerOne {
            height: 74px;
            background-color: #FFFFFF;
            border: 0.5px solid #DDDDDD;
            border-radius: 6px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;
            margin-top: 8px;
        }

        .customerOne:hover {
            background: #F5F8FE;
        }

        .customerOneImage {
            width: 20%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .customerOneImage div {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .customerOneImage div img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .customerOneContent {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
        }

        .customerOnePoints {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            letter-spacing: 0.1px;
            color: #1059FA;
        }

        .customerOneContentName {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #141414;
        }

        .customerOneContentEmail {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: #60606E;

        }


        .customerSearch {
            height: 48px;
            background: #FFFFFF;
            border: 0.5px solid #DDDDDD;
            border-radius: 4px;
            padding: 10px;
            margin-top: 8px;
            margin-bottom: 16px;

        }

        .lablSearch {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #141414;
        }

        .oneCustomerMain {
            display: flex;
            flex-direction: column;
            padding: 20px;
            padding-left: 60px;
            padding-right: 20px;
            align-items: center;
            height: 100%;
        }

        .oneCustomerMainSubOne {
            width: 100%;
            height: 121px;
            background: #FFFFFF;
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .oneCustomerMainSubOneChild {
            width: 90%;
            display: flex;
            // align-items: center;
        }

        .oneCustomerMainSubOneChildOne {
            display: flex;
            align-items: center;
            width: 50%;
        }

        .oneCustomerMainSubOneChildTwo {
            width: 50%;
        }

        .oneCustomerMainSubOneChildOneImage {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin: 0 !important;
        }

        .oneCustomerMainSubOneChildOneImage img {
            object-fit: cover;
            width: 100%;
        }

        .oneCustomerMainSubOneChildOneInfo {
            padding-left: 20px;
        }

        .oneCustomerMainSubOneChildOneInfo h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #141414;
        }

        .oneCustomerMainSubOneChildOneInfo h3 {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: #60606E;

        }

        .oneCustomerMainSubOneChildOneInfo p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: #60606E;

        }

        .oneCustomerMainSubOneChildTwo {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
        }

        .oneCustomerMainSubTwo {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .oneCustomerMainSubTwo button:first-child {
            width: 49%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            gap: 8px;
            width: 385px;
            height: 72px;
            border: none;
            outline: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #FFFFFF;
            background: #1059FA;
            border-radius: 6px;
            margin-top: 33px;


        }

        .oneCustomerMainSubTwo button:last-child {
            width: 49%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            gap: 8px;
            width: 385px;
            height: 72px;
            border: none;
            outline: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            background: #F5F8FE;
            color: #1059FA;
            border-radius: 6px;
            margin-top: 33px;


        }

        .oneCustomerMainSubThree {
            margin-top: 49px;
            width: 100%;
            text-align: left;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #000000;
            margin-bottom: 16px;
        }

        .caption {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.3px;
            color: #60606E;
            margin-top: 4px;
        }

        .dealMain {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 8px;
            overflow-y: scroll;
        }

        .oneCustomerMainSubFour {
            width: 100%;
        }

        .condition {
            margin-top: 8px;
            // width: 75px;
            width: fit-content;
            height: 28px;
            padding: 15px;
            background: #1059FA;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            letter-spacing: 0.1px;

            /* General/White */

            color: #FFFFFF;

        }

        .HeadlineH20M {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #010101;
        }

        .read-login-black {
            display: block;
        }

        .read-login-white {
            display: none;
        }

        .scanner-white {
            display: none;
        }

        .scanner-black {
            display: block;
        }
    }

    @media screen and (min-width:1350px) {
        .oneCustomerMainSubTwo {
            // width: 100%;
            // display: flex;
            // justify-content: space-around !important;
            width: 100%;


        }

        .oneCustomerMainSubTwo button:first-child {
            width: 49% !important;

        }

        .oneCustomerMainSubTwo button:last-child {
            width: 49% !important;
        }
    }

    @media screen and (max-width:600px) {

        .scanner-white {
            display: block;

        }

        .scanner-black {
            display: none;
        }

        .read-login-black {
            display: none;
        }

        .read-login-white {
            display: block;
        }

        .forleft-pad {
            margin-top: 15px !important;
        }

        .blue-nav {
            background: #1059FA;
            width: 100% !important;
            // color: white;
        }

        .for-white {
            color: #FFFFFF;
        }

        #ReadFlow {
            flex-direction: column !important;
            height: auto;

            .res-search-div {
                width: 100% !important;

            }

            .res-search-div1 {
                width: 100%;

            }

            .customerMain {
                display: flex;
                overflow-x: scroll;
                gap: 10px;
            }

            .customerOne {
                width: 60%;
            }

            .oneCustomerMain {
                width: 95% !important;
                padding: 20px;
                padding-left: 0px;
                padding-right: 0px;
                align-items: center;
                height: auto;
            }

            .oneCustomerMainSubOne {

                height: 100px;


            }

            .oneCustomerMainSubFour {
                overflow-y: scroll;
            }

            .customerOnePoints {

                padding-bottom: 17px;
            }

            .bt-res {
                height: 50px !important;
            }
        }
    }
}