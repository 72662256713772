@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/montserrat/Montserrat-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/HelveticaNeueLt.ttf') format('ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/poppins/Poppins-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/poppins/Poppins-Medium.ttf') format('ttf');
}