.sign-in {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: 0.1px;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.user-id {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.1px;

    /* Light/Heading */

    color: #141414;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.input-field {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 396px;
    height: 48px;

    /* White */

    background: #FFFFFF;
    /* Light/Border 1 */

    border: 1px solid #999B9D;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    width: 100%;
    height: auto;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    background: #1059FA;
}

.center {
    justify-content: center;
}

.text-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    width: 100%;
    height: auto;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.shadow {
    background: #FFFFFF;
    box-shadow: 0px 12px 48px rgba(26, 33, 52, 0.11);
    border-radius: 4px;
}

@media only screen and (max-width: 600px) {

    .for-responsiveLogin {

        padding: 0px !important;
    }

    .res-login {
        display: flex;
        align-items: center;
        height: 100vh !important;
    }

    .shadow {
        box-shadow: none !important;
    }

    .res-id {
        padding-bottom: 100px;
    }

    .button {

        width: 327px;
        padding: 8px;
    }

    .cont-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }

}